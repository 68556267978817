import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantIdInitialState } from '../../../interfaces/tenantIdInitialState';
import { RootState } from '../../store';

const initialState: TenantIdInitialState = {
  value: null,
};

export const TenantIdSlice = createSlice({
  name: 'tenantId',
  initialState,
  reducers: {
    setTenantId: (state, action: PayloadAction<string | null>) => {
      state.value = action.payload;
    },
  },
});

export const { setTenantId } = TenantIdSlice.actions;

export const selectTenantID = (state: RootState) => state.tenantId;

export default TenantIdSlice.reducer;
