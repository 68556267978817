import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeycloakConfig } from 'keycloak-js';
import { RootState } from '../../store';

export interface KeycloackConfigState {
  value: KeycloakConfig | null;
}

const initialState: KeycloackConfigState = {
  value: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const KeyCloakConfigSlice = createSlice({
  name: 'keycloackConfig',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    registerKeycloackConfig: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { registerKeycloackConfig } = KeyCloakConfigSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectKeycloackConfig = (state: RootState) =>
  state.keycloackConfig.value;

export default KeyCloakConfigSlice.reducer;
