import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './assets/css/App.scss';
import './assets/css/skeleton-loading.scss';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './shared/state/store';

const query: boolean = window.location.toString().includes('app.actipay.fr');
const instance = createInstance({
  urlBase: '//tracking.intractiv.com',
  siteId: query ? 4 : 1,
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
