import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface VisibilityState {
  value: boolean;
}

const initialState: VisibilityState = {
  value: false,
};

export const VisibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setVisibility } = VisibilitySlice.actions;

export const selectVisibility = (state: RootState) => state.visibility.value;

export default VisibilitySlice.reducer;
