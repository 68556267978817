import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  date: null,
};

export const LastDateIncentiveReloadMouvementSlice = createSlice({
  name: 'lastDateReloadMovement',
  initialState,
  reducers: {
    setDateReload: (state, action) => {
      state.date = action.payload;
    },
  },
});

export const { setDateReload } = LastDateIncentiveReloadMouvementSlice.actions;
export const selectDateReload = (state: RootState) =>
  state.lastDateReloadMovement;
export default LastDateIncentiveReloadMouvementSlice.reducer;
