import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface VisibilityState {
  value: boolean;
}

const initialState: VisibilityState = {
  value: false,
};

export const ReplanNotificationMappingSlice = createSlice({
  name: 'replanNotificationMapping',
  initialState,
  reducers: {
    setReplanNotificationMapping: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

export const { setReplanNotificationMapping } =
  ReplanNotificationMappingSlice.actions;

export const selectReplanNotificationMapping = (state: RootState) =>
  state.replanNotificationMapping.value;

export default ReplanNotificationMappingSlice.reducer;
