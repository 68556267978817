import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';

const initialState = {
    value: false
};

export const DetectDataIntegrationSubmitSlice = createSlice({
    name: 'dataIntegrationSubmit',
    initialState,
    reducers: {
        detectDataIntegrationSubmit: (state, action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const {detectDataIntegrationSubmit} =
    DetectDataIntegrationSubmitSlice.actions;

export const selectDetectDataIntegrationSubmit = (state: RootState) =>
    state.detectDataIntegrationSubmit.value;


export default DetectDataIntegrationSubmitSlice.reducer;
