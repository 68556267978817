import React, { cloneElement, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GenericButtonProps } from '../interfaces/GenericButtonProps';
import I18nWrapper from './i18n-wrapper';

const GenericButton = (props: GenericButtonProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsActive(props?.active || false);
  }, [props?.active]);

  return (
    <Button
      disabled={props?.disabled || props?.loading}
      size="sm"
      active={isActive}
      type={props.type}
      variant={props.variant}
      className={props.linkStyle ? 'link-button' : props.className}
      onClick={() => {
        if (!props.loading) {
          props.action();
        }
      }}
      aria-label={t(props.label)}
      data-testid={props.testid}
      data-qa={props.testid}
      style={{
        ...props.style,
        ...(props.heightFitContent && { height: 'fit-content' }),
      }}
    >
      {!props.loading && props.prependElement && (
        <>{cloneElement(props.prependElement)}</>
      )}
      {props.loading ? (
        <Spinner className="spinner-icon" animation="border" size="sm" />
      ) : (
        <I18nWrapper
          translateKey={props.label}
          options={{ ...props.labelOptions }}
        />
      )}
      {!props.loading && props.appendElement && (
        <>{cloneElement(props.appendElement)}</>
      )}
    </Button>
  );
};

GenericButton.defaultProps = {
  label: '',
  type: 'button',
  variant: 'primary',
  action: () => {
    // sonar
  },
  linkStyle: false,
  testid: 'button',
  loading: false,
  heightFitContent: false,
};

export default GenericButton;
