import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState = {
  value: null,
};

export const RowSelectSlice = createSlice({
  name: 'rowSelect',
  initialState,
  reducers: {
    setRowSelect: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const { setRowSelect } = RowSelectSlice.actions;
export const selectRowSelect = (state: RootState) => state.rowSelect;
export default RowSelectSlice.reducer;
