import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import { KeycloakConfig } from 'keycloak-js'; // Define a service using a base URL and expected endpoints

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    getOidcConfiguration: builder.query<KeycloakConfig, void>({
      query: () => `/public/oidc-configuration`,
    }),
  }),
});

export const { useGetOidcConfigurationQuery } = publicApi;
