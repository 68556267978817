import type { InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { store } from '../state/store';

const axiosInstance = axios.create();
const axiosInstanceErrorOn202 = axios.create();

const _requestConfig = (request: InternalAxiosRequestConfig<any>) => {
  if (request && request.url?.includes('/api')) {
    const headers: Record<string, string> = {};
    const stores = store.getState();
    headers['Authorization'] = `Bearer ${stores.token.value}`;

    if (stores.tenantId.value) {
      headers['X-TenantId'] = stores.tenantId.value;
    }
    request.headers = headers as AxiosRequestHeaders;
  }
  return request;
};

axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>) => {
    return _requestConfig(request);
  },
  (error: AxiosError) => Promise.reject(error.toJSON())
);

axiosInstanceErrorOn202.interceptors.request.use(
  (request: InternalAxiosRequestConfig<any>) => {
    return _requestConfig(request);
  },
  (error: AxiosError) => Promise.reject(error.toJSON())
);

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => res.data,
  (error: AxiosError) => {
    return Promise.reject(error.toJSON());
  }
);
axiosInstanceErrorOn202.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 202) {
      const err: any = new Error('Retry');
      err.config = response.config; // axios-retry using this
      err.response = response; // optional, if you need for retry condition
      throw err;
    }
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error.toJSON());
  }
);

export { axiosInstanceErrorOn202 };
export default axiosInstance;
